import React, { useState, useEffect } from "react";
import DynamicScrollToTop from "../DynamicScrollToTop";
import HeroSection from "../Components/HeroSection";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import FeaturedPost from "../Components/FeaturedPost";
import axios, { Base_URL } from "../Axios";
import Skeleton from "@material-ui/lab/Skeleton";

import "./AboutPageContainer.css";

import Img from "../Assets/CarouselImages/bg_2.jpg";

const info = {
  image: Img,
  imageText: "About",
  title: "About Us",
  breadcrumbs: " Home / About Us",
};

function AboutPageContainer() {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (myData.length <= 0) {
      setLoading(true);
      axios
        .get("/visual/get-data/")
        .then((response) => {
          const data = response.data;
          setMyData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("An Error Occur while fetching data. ", error);
          setLoading(false);
        });
    }
  }, [setLoading, myData.length]);

  const featuredPosts = [
    {
      title: myData.director1_name,
      description: myData.director1_info,
      image: `${Base_URL}${myData.director1_image}`,
      imageText: myData.director1_name,
    },
    {
      title: myData.director2_name,
      description: myData.director2_info,
      image: `${Base_URL}${myData.director2_image}`,
      imageText: myData.director2_name,
    },
  ];

  return (
    <React.Fragment>
      <DynamicScrollToTop />
      <HeroSection data={info} />
      <div className="about-section">
        <Container>
          <Row>
            <Col md="5" className="my-2">
              {loading ? (
                <Skeleton variant="rect" width={410} height={218} />
              ) : (
                <Image
                  src={`${Base_URL}${myData.company_image}`}
                  fluid
                  rounded
                />
              )}
            </Col>
            <Col md="7" className="pl-3 my-2">
              <div className="heading-section">
                <h2 className="mb-4">Welcome To RedFruit Agro</h2>
              </div>
              <div>
                <p>{myData.about_company_p1}</p>
                <p>{myData.about_company_p2}</p>
                <p>{myData.about_company_p3}</p>
              </div>
            </Col>
          </Row>
          <hr className="mt-5" />
          <div className="my-5">
            <Grid container spacing={4}>
              {featuredPosts.map((post, index) => (
                <FeaturedPost key={index} post={post} />
              ))}
            </Grid>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AboutPageContainer;

import React from "react";
import {
  Container,
  Avatar,
  Typography,
  Grid,
  TextField,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { MdLockOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authRegister } from "../redux/authentication/authActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignupPageContainer(props) {
  const classes = useStyles();

  let errorMessage = null;
  if (props.error) {
    errorMessage = <p>{props.error}</p>;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Username: ", event.target.username.value);
    const username = event.target.username.value;
    const email = event.target.email.value;
    const password1 = event.target.password1.value;
    const password2 = event.target.password2.value;

    props.authSignUp(username, email, password1, password2);
  };

  return (
    <div className="m-5 pb-5">
      {errorMessage}
      {props.loading ? (
        <div className="text-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <MdLockOutline size="25" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              className={classes.form}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="username"
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password1"
                    autoComplete="current-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link to="/login/" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authSignUp: (username, email, password1, password2) => {
      dispatch(authRegister(username, email, password1, password2));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPageContainer);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Container, Row, Col } from "react-bootstrap";

import "./HeroSection.css";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
}));

function HeroSection(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <React.Fragment>
      <Paper
        className={`${classes.mainFeaturedPost} text-center`}
        style={{ backgroundImage: `url(${data.image})` }}
      >
        {/* Increase the priority of the hero background image */}
        {
          <img
            style={{ display: "none" }}
            src={data.image}
            alt={data.imageText}
          />
        }
        <div className={classes.overlay} />
        <Container className="p-5">
          <Row>
            <Col className="hero-content">
              <p>{data.breadcrumbs}</p>
              <h1>{data.title}</h1>
            </Col>
          </Row>
        </Container>
      </Paper>
    </React.Fragment>
  );
}

export default HeroSection;

import React, { Component } from "react";
import BaseRouter from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import NavbarContainer from "./Container/NavbarContainer";
import FooterContainer from "./Container/FooterContainer";
import { connect } from "react-redux";
// import * as actionTypes from "./redux/authentication/authActions";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

export class App extends Component {
  // componentDidMount() {
  //   this.props.onTryAutoSignup();
  // }

  render() {
    return (
      <div>
        <Router>
          <NavbarContainer />
          <BaseRouter />
          <FooterContainer />
        </Router>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isauthenticated: state.token !== null,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onTryAutoSignup: () => {
//       dispatch(actionTypes.authCheckState());
//     },
//   };
// };

export default connect()(App);

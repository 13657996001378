import React from "react";
import {
  Container,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { MdLockOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authLogin } from "../redux/authentication/authActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPageContainer(props) {
  const classes = useStyles();

  let errorMessage = null;
  if (props.error) {
    errorMessage = <p>{props.error}</p>;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    props.onAuth(username, password);
    props.history.push('/')
  };
  return (
    <div className="m-5 pb-5">
      {errorMessage}
      {props.loading ? (
        <div className="text-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Container maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <MdLockOutline size="25" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              className={classes.form}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                type="text"
                label="Username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link to="/signup/" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) => {
      dispatch(authLogin(username, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);

import React from "react";
import { Route } from "react-router-dom";
import CarouselArea from "./Container/CarouselArea";
import InfoSection from "./Container/InfoSection";
import HomeListContainer from "./Container/HomeListContainer";
import ShopPageContainer from "./Container/ShopPageContainer";
import ProductDetailContainer from "./Container/ProductDetailContainer";
import LoginPageContainer from "./Container/LoginPageContainer";
import SignupPageContainer from "./Container/SignupPageContainer";
import AboutPageContainer from "./Container/AboutPageContainer";
import ContactPageContainer from "./Container/ContactPageContainer";

const HomeContainer = () => (
  <div>
    <CarouselArea />
    <InfoSection />
    <HomeListContainer />
  </div>
);

const BaseRouter = () => (
  <div>
    <Route exact path="/" component={HomeContainer} />
    <Route exact path="/login/" component={LoginPageContainer} />
    <Route exact path="/signup/" component={SignupPageContainer} />
    <Route exact path="/shop/" component={ShopPageContainer} />
    <Route exact path="/about/" component={AboutPageContainer} />
    <Route exact path="/contact/" component={ContactPageContainer} />
    <Route exact path="/shop/:productSlug/" component={ProductDetailContainer} />
  </div>
);

export default BaseRouter;

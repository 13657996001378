import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

import Img1 from "../Assets/CarouselImages/bg_1.jpg";
import Img2 from "../Assets/CarouselImages/bg_2.jpg";
import Img3 from "../Assets/CarouselImages/bg_3.jpg";

function CarouselArea() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel className="my-caption" activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className="d-block w-100" style={{ filter: "brightness(80%)" }} src={Img1} alt="First slide" />
          <Carousel.Caption>
            <h3>We Serve Fresh Vegetables and Fruits</h3>
            <p>We deliver Fresh vegitables and Fruits</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" style={{ filter: "brightness(80%)" }} src={Img2} alt="Second slide" />

          <Carousel.Caption>
            <h3>100% Fresh and Organic Foods</h3>
            <p>We deliver organic vegetables and foods</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" style={{ filter: "brightness(80%)" }} src={Img3} alt="Third slide" />

          <Carousel.Caption>
            <h3>We Serve Fresh Vegetables and Fruits</h3>
            <p>We deliver organic vegitables and Fruits</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default CarouselArea;

import React, { useState, useEffect } from "react";
import DynamicScrollToTop from "../DynamicScrollToTop";
import HeroSection from "../Components/HeroSection";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import { TextField, CircularProgress, Snackbar } from "@material-ui/core";
import axios from "../Axios";

import "./ContactPageContainer.css";

import Img from "../Assets/CarouselImages/bg_3.jpg";

const info = {
  image: Img,
  imageText: "Contact",
  title: "Contact Us",
  breadcrumbs: " Home / Contact Us",
};

function ContactPageContainer() {
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    if (myData.length <= 0) {
      axios
        .get("/visual/get-data/")
        .then((response) => {
          const data = response.data;
          setMyData(data);
        })
        .catch((error) => {
          console.log("An Error Occur while fetching data. ", error);
        });
    }
  });

  const handleSnackOpen = () => {
    setOpenSnack(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const name = event.target.name.value;
    const email = event.target.email.value;
    const phoneNumber = event.target.phoneNumber.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    // console.log(name, email, phoneNumber, subject, message);
    axios
      .post("/visual/contact/", {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        subject: subject,
        message: message,
      })
      .then((response) => {
        const data = response.data;
        setLoading(false);
        if (data.success) {
          setSuccessMsg("Message Send Successfully.");
        } else if (data.error) {
          setErrorMsg("Error While sending message!!!");
        } else {
          setErrorMsg("Something went wrong! Try again...");
        }
        handleSnackOpen();
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
        setErrorMsg(error.message);
        handleSnackOpen();
      });
  };

  return (
    <React.Fragment>
      <DynamicScrollToTop />
      <HeroSection data={info} />
      <div className="contact-section bg-light">
        <Container>
          <Row className="contact-info">
            <Col md="4">
              <div className="info bg-white p-3">
                <p>
                  <span>Address: </span> <br />
                  {myData.full_address}
                </p>
              </div>
            </Col>
            <Col md="4" className="">
              <div className="info bg-white p-3">
                <p>
                  <span>Phone: </span> <br />
                  <a href={`tel://${myData.phone_number}`}>
                    {myData.phone_number}
                  </a>
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info bg-white p-3">
                <p>
                  <span>Email: </span> <br />
                  <a href={`mailto:${myData.email_address}`}>
                    {myData.email_address}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
          {loading ? (
            <div className="text-center my-5">
              <CircularProgress size={80} />
            </div>
          ) : (
            <Row className="my-5">
              <Col md="8" className="mx-auto">
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                  className="bg-white p-5 shadow contact-form"
                >
                  <TextField
                    fullWidth
                    required
                    id="name"
                    type="text"
                    label="Your Name"
                    variant="outlined"
                    autoFocus
                  />
                  <br /> <br />
                  <TextField
                    fullWidth
                    required
                    id="email"
                    type="email"
                    label="Your Email"
                    variant="outlined"
                  />
                  <br /> <br />
                  <TextField
                    fullWidth
                    required
                    id="phoneNumber"
                    type="text"
                    label="Phone Number"
                    variant="outlined"
                  />
                  <br /> <br />
                  <TextField
                    fullWidth
                    required
                    id="subject"
                    type="text"
                    label="Subject"
                    variant="outlined"
                  />
                  <br /> <br />
                  <TextField
                    fullWidth
                    required
                    id="message"
                    label="Message"
                    multiline
                    rows={6}
                    variant="outlined"
                  />
                  <br /> <br />
                  <Button
                    variant="success"
                    className="custom-btn"
                    size="lg"
                    type="submit"
                    block
                  >
                    Submit
                  </Button>
                </form>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          variant={successMsg ? "success" : "danger"}
          dismissible
        >
          {successMsg ? successMsg : errorMsg}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default ContactPageContainer;

import React, { useState, useEffect } from "react";
import HeroSection from "../Components/HeroSection";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import DynamicScrollToTop from "../DynamicScrollToTop";
import axios, { Base_URL } from "../Axios";

import Img from "../Assets/CarouselImages/bg_2.jpg";

import "./ProductDetailContainer.css";
import Skeleton from "@material-ui/lab/Skeleton";

const info = {
  image: Img,
  imageText: "Shop",
  title: "Product Details",
  breadcrumbs: " Home / Shop / Product Detail ",
};

const handleClick = (slug) => {
  console.log("its also working...", slug);
};

function ProductDetailContainer(props) {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productData.length <= 0) {
      setLoading(true);
      axios
        .get(`/product/${props.match.params.productSlug}/`)
        .then((response) => {
          const data = response.data;
          setProductData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("An Error Occur while fetching data. ", error);
          setLoading(false);
        });
    }
  }, [setLoading, productData.length, props]);
  return (
    <React.Fragment>
      <DynamicScrollToTop />
      <HeroSection data={info} />
      <div className="detail-section">
        <Container>
          <Row>
            <Col lg="6" className="mb-5">
              {loading ? (
                <Skeleton variant="rect" width={500} height={218} />
              ) : (
                <Image src={`${Base_URL}${productData.image}`} fluid rounded />
              )}
            </Col>
            <Col lg="6" className="product-details">
              <h3>{productData.title}</h3>
              <div className="location">
                <p className="text-left mr-4">
                  <a href="/" className="mr-2" style={{ color: "#000" }}>
                    Location: -
                    <span style={{ color: "#bbb" }}>
                      {productData.location_name}
                    </span>
                  </a>
                </p>
              </div>
              <p className="price">
                <span>
                  RS. {productData.price} / {productData.volume_type}
                </span>
              </p>
              <p>{productData.description}</p>
              <p>
                <Button
                  onClick={() => {
                    handleClick(props.match.params.productSlug);
                  }}
                  className="my-btn btn-success py-3 px-5"
                >
                  Add To Cart
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ProductDetailContainer;

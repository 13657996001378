import React, { useState } from "react";
import { Nav, Container, Image } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authLogout } from "../redux/authentication/authActions";

import "./NavbarContainer.css";
import Img from "../Assets/RedFruitLogo.png";

function NavbarContainer(props) {
  const [navExpanded, setNavExpanded] = useState(false);

  const toggleNavExpanded = (expanded) => {
    setNavExpanded(expanded)
  }

  const closeNav = () => {
    setNavExpanded(false);
  };

  return (
    <>
      <Navbar collapseOnSelect={true} onToggle={toggleNavExpanded} expanded={navExpanded} className="color-nav" sticky="top" expand="lg">
        <Container>
          <Navbar.Brand href="/" className="brand-name">
            <Image
              src={Img}
              fluid
              width={25}
              style={{ marginRight: 10 }}
              rounded
            />
            <span style={{ color: "#E44236" }}>Red</span> Fruits Agro Pvt Ltd
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link onClick={() => { closeNav() }} to="/">Home</Link>
              <Link onClick={() => { closeNav() }} to="/shop/">Shop</Link>

              {props.isAuthenticated ? (
                <Link
                  to="#"
                  onClick={() => {
                    props.authLogout();
                    closeNav()
                  }}
                >
                  Logout
                </Link>
              ) : (
                <Nav>
                  <Link onClick={() => { closeNav() }} to="/login/">Login</Link>
                  <Link onClick={() => { closeNav() }} to="/signup/">Sign Up</Link>
                </Nav>
              )}

              <Link onClick={() => { closeNav() }} to="/about/">About</Link>
              <Link onClick={() => { closeNav() }} to="/contact/">Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: () => {
      dispatch(authLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarContainer);

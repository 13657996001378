import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProductListView from "../Components/ProductListView";
import axios from "../Axios";

import "./HomeListContainer.css";
import { CircularProgress } from "@material-ui/core";

function ProductsListContainer() {
  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productsData.length <= 0) {
      setLoading(true);
      axios
        .get("/product/list/")
        .then((response) => {
          const data = response.data;
          setProductsData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [setLoading, productsData.length]);

  return (
    <div className="product-list-section">
      <Container>
        <Row className="mb-3 pb-3 text-center">
          <Col className="heading-section">
            <span className="subheading">Featured Product</span>
            <h2 className="mb-4">Our Products</h2>
            <p>We always provide high quality products to serve you</p>
          </Col>
        </Row>
      </Container>
      {loading ? (
        <div className="text-center py-5">
          <CircularProgress size={80} />
        </div>
      ) : (
        <ProductListView data={productsData} />
      )}
    </div>
  );
}

export default ProductsListContainer;

import * as actionTypes from "./authTypes";
import axios from "../../Axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const authLogout = () => {
  localStorage.removeItem("token");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

// ----------- Working Function --------------

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/rest-auth/login/", {
        username: username,
        password: password,
      })
      .then((response) => {
        const token = response.data.key;
        localStorage.setItem("token", token);
        dispatch(authSuccess(token));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(authFail(errorMsg));
      });
  };
};

export const authRegister = (username, email, password1, password2) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/rest-auth/registration/", {
        username: username,
        password1: password1,
        password2: password2,
        email: email,
      })
      .then((response) => {
        const token = response.data.key;
        localStorage.setItem("token", token);
        dispatch(authSuccess(token));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(authFail(errorMsg));
      });
  };
};

// Unwanted function 
export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(authLogout());
    }
  };
};

import React, { useState, useEffect } from "react";
import HeroSection from "../Components/HeroSection";
import ProductListView from "../Components/ProductListView";
import { Container, Row, Col } from "react-bootstrap";
import { TextField, Fab, CircularProgress } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";
import DynamicScrollToTop from "../DynamicScrollToTop";
import axios from "../Axios";

import Img from "../Assets/CarouselImages/bg_1.jpg";

function ShopPageContainer() {
  const info = {
    image: Img,
    imageText: "Shop",
    title: "Products",
    breadcrumbs: " Home / Shop ",
  };

  const [productsData, setProductsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productsData.length <= 0) {
      setLoading(true);
      axios
        .get("/product/list/")
        .then((response) => {
          const data = response.data;
          setProductsData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [setLoading, productsData.length]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const locationName = event.target.search.value;
    axios
      .get(`/search/?q=${locationName}`)
      .then((response) => {
        const data = response.data;
        setProductsData(data);
      })
      .catch((error) => {
        console.log("An error occur while fetching data. ", error);
      });
  };
  return (
    <React.Fragment>
      <DynamicScrollToTop />
      <HeroSection data={info} />
      <Container>
        <Row>
          <Col className="text-center">
            <form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <TextField
                id="search"
                label="Search By Location"
                variant="outlined"
                className="pr-2"
              />
              <Fab type="submit" color="primary" aria-label="add">
                <FaSearch />
              </Fab>
            </form>
          </Col>
          <Col sm="12">
            <hr />
          </Col>
        </Row>
      </Container>
      {loading ? (
        <div className="text-center py-5">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Container>
          {productsData.message ? (
            <Row>
              <Col className="text-center p-5">
                <h2>{productsData.message}</h2>
              </Col>
            </Row>
          ) : (
            <ProductListView data={productsData} />
          )}
        </Container>
      )}
    </React.Fragment>
  );
}

export default ShopPageContainer;

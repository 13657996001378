import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagramSquare,
  FaTwitter,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsappSquare,
} from "react-icons/fa";
import axios from "../Axios";
import Img from "../Assets/RedFruitLogo.png";
import { Link } from "react-router-dom";

import "./FooterContainer.css";

function FooterContainer() {
  const [myData, setMyData] = useState([]);

  useEffect(() => {
    if (myData.length <= 0) {
      axios
        .get("/visual/get-data/")
        .then((response) => {
          const data = response.data;
          setMyData(data);
        })
        .catch((error) => {
          console.log("An Error Occur while fetching data. ", error);
        });
    }
  });

  return (
    <div className="footer-section border-top">
      <footer className="footer-area">
        <Container>
          <Row className="mb-5 border-bottom">
            <Col lg="4">
              <div className="footer-widget mb-5">
                <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                  <Image
                    src={Img}
                    fluid
                    width={25}
                    height={20}
                    style={{ marginRight: 10 }}
                    rounded
                  />
                  <h2 style={{ marginBottom: 0 }}>Red Fruits Agro Pvt Ltd</h2>
                </div>
                <p>{myData.company_slogan}</p>
                <ul className="mt-5 list-unstyled">
                  <li>
                    <a
                      href={myData.facebook_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaFacebook size="24" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={myData.instagram_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaInstagramSquare size="24" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={myData.twitter_link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaTwitter size="24" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/message/EBSE45BN2R6JM1"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FaWhatsappSquare size="24" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="4">
              <div className="footer-widget mb-5">
                <h2>Menu</h2>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/shop/" className="py-2">Shop</Link>
                  </li>
                  <li>
                    <Link to="/about/" className="py-2">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact/" className="py-2">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="4">
              <div className="footer-widget mb-5">
                <h2>Have a Questions?</h2>
                <div className="mb-3 block-addr">
                  <Row>
                    <Col sm="1" className="text-right">
                      <FaMapMarkerAlt size="20" />
                    </Col>
                    <Col sm="11" className="text-left">
                      <h6>Maharashtra, India</h6>
                      <p>{myData.footer_address}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="1" className="text-right">
                      <FaPhoneAlt size="20" />
                    </Col>
                    <Col sm="11" className="text-left">
                      <h6>{myData.phone_number}</h6>
                      <p>Mon to Sat - 8am to 8pm</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="1" className="text-right">
                      <FaEnvelope size="20" />
                    </Col>
                    <Col sm="11" className="text-left">
                      <h6>{myData.email_address}</h6>
                      <p>Ask your query anytime!</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-center">
              <p>
                Copyright &copy; 2020 | Red Fruits Argo PVT. LTD. | All Rights
                Reserved | Design and Developed By
                <a
                  href="https://chetanbhogade.github.io/portfolio/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Chetan Bhogade
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default FooterContainer;

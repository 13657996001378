import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaShippingFast,
  FaAward,
  FaCheckCircle,
  FaHeadset,
} from "react-icons/fa";

import "./InfoSection.css";

function InfoSection() {
  return (
    <div className="info-section">
      <Container>
        <Row className="bg-white shadow p-4 text-center info-services">
          <Col lg="3" md="6" sm="6" className="mb-3">
            <FaShippingFast className="mb-3" color="#82ae46" size="40" />
            <h3>Fast Shipping</h3>
            <span>On Order Over $100</span>
          </Col>
          <Col lg="3" md="6" sm="6" className="mb-3">
            <FaAward className="mb-3" color="#82ae46" size="40" />
            <h3>Always Fresh</h3>
            <span>Product Well Package</span>
          </Col>
          <Col lg="3" md="6" sm="6" className="mb-3">
            <FaCheckCircle className="mb-3" color="#82ae46" size="40" />
            <h3>SUPERIOR QUALITY</h3>
            <span>Quality Products</span>
          </Col>
          <Col lg="3" md="6" sm="6" className="mb-3">
            <FaHeadset className="mb-3" color="#82ae46" size="40" />
            <h3>Full Support</h3>
            <span>24/7 Support</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default InfoSection;

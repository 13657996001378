import axios from "axios";

const instance = axios.create({
  baseURL: "https://redfruitagro.com/",
  // baseURL: "http://127.0.0.1:8000/",
});

export const Base_URL = "https://redfruitagro.com";

export default instance;
